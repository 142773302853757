import React from "react"
import BannerImage from "!svg-react-loader!../images/vectors/banner.svg"
import FormComponent from "./Demo/form-component"

const Banner = ({onFormSubmit}) => (
  <div className='row banner-outer' id="home">
    <div className='banner-title mobile'>Smart Dispensary</div>
    <div className='col-md-6 col-sm-12 banner-content'>
      <div className='banner-title desktop'>Smart Dispensary</div>
      <div className='form-container'>
        <FormComponent onSubmit={onFormSubmit}/>
      </div>
    </div>
    <div className='col-md-6 col-sm-12 image-banner'>
      <BannerImage/>
    </div>
  </div>
)

export default Banner

