import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/banner"
import FooterDemo from "../components/footer-demo"
import ImageWithText from "../components/Features/image-with-text"
import DemoErrorForm from "../components/Demo/error-form"
import DemoSuccessForm from "../components/Demo/success-form"
import ReactModal from "react-modal"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(115,121,141, 0.75)",
    zIndex: 9
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "438px",
    maxHeight: "550px",
    boxShadow: "0px 15px 26px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    padding: 0,
    border: 0,
    errorMsgMbDemo: false
  }
}

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      success: false,
      error: false
    }
  }

  onClose = () => {
    this.setState({
      success: false,
      error:false
    })
  }

  showModal = (key) => {
    this.setState({
      [key]: true
    })
  }

  render() {
    const { success, error } = this.state
    const isOpen = success || error
    return (
      <Layout>
        <ReactModal
          isOpen={isOpen}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          {error ? <DemoErrorForm onClose={this.onClose}/> :
            <DemoSuccessForm onClose={this.onClose}/>
          }
        </ReactModal>
        <SEO title="Home"/>
        <Banner onFormSubmit={this.showModal}/>
        <ImageWithText type='dispensary'/>
        <ImageWithText type='procurement'/>
        <ImageWithText type='support'/>
        <ImageWithText type='benefits'/>
        <FooterDemo onFormSubmit={this.showModal}/>
      </Layout>
    )
  }
};
